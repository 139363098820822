// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1, .h1 {
    @include heading($font-domaine-regular);

    font-size: rem(40px);
    line-height: rem(48px);
    letter-spacing: -0.02em;
}

h2, .h2 {
    @include heading($font-domaine-regular);

    font-size: rem(32px);
    line-height: rem(40px);
    letter-spacing: -0.01em;
}

h3, .h3 {
    @include heading($font-domaine-regular);

    font-size: rem(24px);
    line-height: rem(30px);
}

h4, .h4 {
    @include heading($font-avenir-next-regular);

    font-size: rem(24px);
    font-weight: 500;
    line-height: rem(30px);
    letter-spacing: -0.01em;
}

h5, .h5 {
    @include heading($font-avenir-next-regular);

    font-size: rem(20px);
    font-weight: 500;
    line-height: rem(24px);
    letter-spacing: -0.01em;
}

h6, .h6 {
    @include heading($font-avenir-next-regular);

    font-size: rem(16px);
    line-height: rem(24px);
}

@include media-breakpoint-down(md) {
    h1, .h1 {
        font-size: rem(28px);
        line-height: rem(36px);
        letter-spacing: -0.01em;
    }

    h2, .h2 {
        font-size: rem(24px);
        line-height: rem(30px);
        letter-spacing: -0.01em;
    }

    h3, .h3 {
        font-size: rem(20px);
        line-height: rem(30px);
        letter-spacing: -0.01em;
    }

    h4, .h4 {
        font-size: rem(20px);
        font-weight: 500;
        line-height: rem(26px);
    }

    h5, .h5 {
        font-size: rem(18px);
        font-weight: 500;
        line-height: rem(22px);
    }

    h6, .h6 {
        font-size: rem(16px);
        font-weight: 400;
        line-height: rem(24px);
    }
}

.h1_mobile {
    @include heading($font-domaine-regular);

    font-size: rem(28px);
    line-height: rem(36px);
    letter-spacing: -0.01em;
}

.h2_mobile {
    @include heading($font-domaine-regular);

    font-size: rem(24px);
    line-height: rem(30px);
    letter-spacing: -0.01em;
}

.h3_mobile {
    @include heading($font-domaine-regular);

    font-size: rem(20px);
    line-height: rem(30px);
    letter-spacing: -0.01em;
}

.h4_mobile {
    @include heading($font-avenir-next-regular);

    font-size: rem(20px);
    font-weight: 500;
    line-height: rem(26px);
}

.h5_mobile {
    @include heading($font-avenir-next-regular);

    font-size: rem(18px);
    font-weight: 500;
    line-height: rem(22px);
}

.h6_mobile {
    @include heading($font-avenir-next-regular);

    font-size: rem(16px);
    font-weight: 400;
    line-height: rem(24px);
}

.lead {
    @include font-size($lead-font-size);

    font-weight: $lead-font-weight;
}

// Type display classes

.display-1 {
    @include heading($font-domaine-regular);

    font-size: rem(64px);
    font-weight: 400;
    line-height: rem(72px);
    letter-spacing: -0.03em;
    color: $black;

    @include media-breakpoint-down(md) {
        font-size: rem(40px);
        font-weight: 400;
        line-height: rem(48px);
        letter-spacing: -0.03em;
    }
}

.display-2 {
    @include heading($font-domaine-regular);

    font-size: rem(48px);
    font-weight: 400;
    line-height: rem(56px);
    letter-spacing: -0.03em;
    color: $black;

    @include media-breakpoint-down(md) {
        font-size: rem(36px);
        font-weight: 400;
        line-height: rem(42px);
        letter-spacing: -0.03em;
    }
}

.display-1-mobile {
    @include heading($font-domaine-regular);

    font-size: rem(40px);
    font-weight: 400;
    line-height: rem(48px);
    letter-spacing: -0.03em;
    color: $black;
}

.display-2-mobile {
    @include heading($font-domaine-regular);

    font-size: rem(36px);
    font-weight: 400;
    line-height: rem(42px);
    letter-spacing: -0.03em;
    color: $black;
}

//
// Horizontal rules
//

hr {
    @include hrtype();
}

//
// Emphasis
//

small,
.small {
    @include font-size($small-font-size);

    font-weight: $font-weight-normal;
}

mark,
.mark {
    padding: $mark-padding;
    background-color: $mark-bg;
}

//
// Lists
//

.list-unstyled {
    @include list-unstyled;
}

// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled;
}

.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}

//
// Misc
//

// Builds on `abbr`
.initialism {
    @include font-size(90%);

    text-transform: uppercase;
}

// Blockquotes
.blockquote {
    @include font-size($blockquote-font-size);

    margin-bottom: $spacer;
}

.blockquote-footer {
    @include font-size($blockquote-small-font-size);

    display: block;
    color: $blockquote-small-color;

    &::before {
        content: "\2014\00A0"; // em dash, nbsp
    }
}

.label-eyebrow {
    @include labeltype();

    font-size: rem(12px);
    line-height: rem(15.6px);
    letter-spacing: 5%;
    text-transform: uppercase;
}

.label-large {
    @include labeltype();

    font-size: rem(16px);
    line-height: rem(20.8px);
}

.label-medium {
    @include labeltype();

    font-size: rem(14px);
    line-height: rem(18.2px);
}

.label-medium-demi {
    @include labeltype();

    font-family: $font-avenir-next-medium;
    font-size: rem(14px);
    font-weight: 600;
    line-height: rem(18.2px);
}

.label-small {
    @include labeltype();

    font-size: rem(12px);
    line-height: rem(15.6px);
}

.label-micro {
    @include labeltype();

    font-size: rem(10px);
    line-height: rem(13px);
}

.paragrph-small {
    font-size: $font-size-base * 0.75;
    line-height: rem(20px);
}

.paragrph-xsmall {
    font-size: $font-size-base * 0.688;
    line-height: rem(16px);

    @include media-breakpoint-down(md) {
        font-size: $font-size-base * 0.625;
    }
}

span {
    .sale {
        color: $brand-main;
    }

    .strike-through {
        color: $gray5;
    }
}

.minicart {
    .strike-through,
    .sale {
        font-size: $font-size-base * 0.75;
    }

    .strike-through {
        margin-right: map-get($spacers, 1);
    }
}

.data-checkout-stage {
    .strike-through,
    .sale {
        font-size: $font-size-base * 0.875;
    }

    .strike-through {
        margin-right: 8px;
    }
}

.product-detail {
    .strike-through {
        margin-right: 8px;
    }
}

.minicart {
    .strike-through,
    .sale {
        font-size: $font-size-base * 0.75;
    }

    .strike-through {
        margin-right: map-get($spacers, 1);
    }
}

.nav-link {
    @include navlinktype();

    &:hover {
        color: $brand-main;
    }
}

nav {
    .navbar-nav {
        @include media-breakpoint-up(lg) {
            justify-content: center;
            max-width: 100%;
            overflow: hidden;
        }

        .nav-link {
            @include media-breakpoint-up(lg) {
                padding-bottom: map-get($spacers, 2);
                border-bottom: 1px solid transparent;

                &:hover {
                    border-color: $brand-main;
                }
            }
        }
    }
}

.link-primary {
    @include linkbase();
}

.link-primary-large {
    font-size: $font-size-base * 0.875;
    line-height: rem(21px);
    text-decoration: underline;
}

.link-secondary {
    @include linkbase();

    text-decoration: underline;
}

.pairings {
    h1,
    h2,
    .h1,
    .h2 {
        margin-top: map-get($spacers, 16);
        margin-bottom: map-get($spacers, 2);
    }

    p {
        margin-bottom: map-get($spacers, 2);
    }

    a {
        margin-bottom: map-get($spacers, 16);
        display: inline-block;
    }
}

.font-weight-bold {
    font-family: $font-avenir-black;
}

.body-regular,
.body-small,
.body-micro,
.body-medium,
p,
body {
    font-family: $font-avenir-next-regular;
    font-weight: 400;
}

.body-regular,
p,
body {
    font-size: rem(14px);
    line-height: rem(20px);
}

.body-small {
    font-size: rem(12px);
    line-height: rem(18px);
}

.body-micro {
    font-size: rem(10px);
    line-height: rem(16px);
}

.body-medium {
    font-size: rem(16px);
    line-height: rem(24px);
}
